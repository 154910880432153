<template>
  <div id="queues">
    <v-container>
      <v-card class="mb-3">
        <v-card-title>{{ formTitle }}</v-card-title>
        <v-card-text class="pb-6">
          <v-form v-model="valid">
            <v-row>
              <v-col>
              <v-text-field
                v-model="queue.name"
                label="Name"
                :rules="noEmptyField"
                required
              ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
              <v-text-field
                v-model="queue.description"
                label="Description"
                :rules="noEmptyField"
                required
              ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="d-flex flex-row justify-center">
              <v-btn
                :disabled="!valid"
                elevation="2"
                @click="save"
                class="mr-1"
              >
                Submit
              </v-btn>
              <v-btn
                elevation="2"
                @click="reset"
                class="ml-1"
              >
                Cancel
              </v-btn>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
      <v-card>
        <v-card-title>Current Queues</v-card-title>
        <v-card-text>
          <div v-if="queuesList.length === 0">
            Nothing found
          </div>
          <div v-if="queuesList.length > 0">
            <v-data-table
              :items="prettyList"
              :headers="headers"
              @click:row="rowClick"
              dense
            >
            </v-data-table>
          </div>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: "QueueManagement",
  computed: {
		...mapGetters({
			authUser: 'auth/user',
			queuesList: 'queues/list'
		}),
    prettyList() {
      return this.queuesList.map(q => {
        return {
          ...q,
          created_display: this.formatDate(q.created),
          updated_display: this.formatDate(q.updated)
        }
      })
    },
    formTitle() {
      if (this.editId > 0) {
        return `Editing Queue: ${this.queue.name}`
      }

      return 'Add New Queue'
    }
  },
  data () {
		return {
      temp: 'Placeholder',
      valid: false,
      editId: 0,
			expanded: [],
      snackbar: false,
      snackmsg: '',
      queue: {
        name: '',
        description: ''
      },
      noEmptyField: [
        v => !!v || "This field must be filled"
      ],
			headers: [
				{ text: 'ID', value: 'id' },
				{ text: 'Name', value: 'name' },
				{ text: 'Description', value: 'description' },
				{ text: 'Created', value: 'created_display' },
				{ text: 'Updated', value: 'updated_display' },
			]
		}
	},
  methods: {
    formatDate(dt) {
      const theDate = new Date(dt);
      const months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN',
                      'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
      return `${theDate.getDate()} ${months[theDate.getMonth()]} ${theDate.getFullYear()}`
    },
    feedbackSnack(msg) {
      const vm = this;
      this.snackmsg = msg;
      this.snackbar = true;
      setTimeout(() => { vm.snackbar = false; }, 5000)
    },
    save() {
      let test = new this.$FeathersVuex.api.Queue({...this.queue})
      test.save()
      .then(() => {
          this.feedbackSnack("Queue saved successfully.")
        })
        .catch((err) => {
          this.feedbackSnack("The queue data ccould not be saved.");
          console.error(err);
        })
    },
    reset() {
      this.queue = { name: '', description: ''}
      this.editId = 0;
    },
    rowClick (evt) {
      // console.log("EVT", JSON.stringify(evt));
      this.editId = parseInt(evt.id);
      this.queue.id = parseInt(evt.id);
      this.queue.name = evt.name;
      this.queue.description = evt.description;
    }
  }
}
</script>
